
import imports from "@/services/imports";
import { downloadAsBlob } from '@/util/file';
import ImportSection from "@/components/modals/ImportSection";
import ImportResult from "@/components/modals/ImportResult";
import { mapGetters, mapActions } from 'vuex';
import CommonMixin from '@/mixins/CommonMixin';
import ToolsMixin from '@/mixins/ToolsMixin';
import { mapState } from 'vuex';
import moment from 'moment';
import stores from '@/services/stores';

export default {
  components: {
    ImportSection,
    ImportResult
  },
  mixins: [CommonMixin, ToolsMixin],
  // props: {
  //   openModel: Boolean,
  // },
  data() {
    return {
      loading: false,
      selectedKPI: 'sls',
      importData: null,
      options: [
        {
          label: this.$t('KPI.sales'),
          value: 'sls',
          fileName:'Sales'
        },
        {
          label: this.$t('KPI.salesTarget'),
          value: 'st',
          fileName:'Sales Target'
        },
        {
          label: this.$t('KPI.traffic'),
          value: 'trf',
          fileName:'Traffic'
        },
        {
          label: this.$t('KPI.staffhours'),
          value: 'sh',
          fileName:'Staff Hours'
        },
        {
          label: this.$store.state.user.translate.regular_operating_hours,
          value: 'roh',
          fileName:'Regular_operating_hours'
        },
        {
          label: this.$store.state.user.translate.special_operating_hours,
          value: 'soh',
          fileName:'Special_operating_hours'
        }
      ],
      selectedSalesMappingTemplate: null,
      salesMappingTemplates: [],
    }
  },
  computed: {
    ...mapGetters('store', [
      'getStoreCodeList']),
      ...mapGetters('user', ['getLocale']),
  },


  methods: {
    ...mapActions('user', ['fetchStores']),
    addStore() {

    },
    updateSelectedKPI(kpi) {
      this.selectedKPI = kpi.value
    },
    async downloadTemplate() {
      const salesResponse = "Store Code,Transaction Date,Transaction Hour,Total Transactions,Items Sold,Sales Amount\nxxx,YYYYMMDD,hhmm,1111,1111,1111";
      const salesTargetResponse = "Store Code,Date,Sales Target\nxxx,YYYYMMDD,1111";
      const trafficResponse = "Store Code,Date,Time,Entrance,IN_OUT,Traffic\nxxx,YYYYMMDD,hhmm,xxx,xxx,xxx";
      const staffHoursResponse = "Store Code,Staff Hour Date,Staff Hour Time,Staff In Store\nxxx,YYYYMMDD,hhmm,xxx";
      const regularHoursResponse = "Store Code,Day,Open or Closed,Open at,Closes at\nxxx,Monday,Open,hhmm,hhmm\nxxx,Tuesday,Open,hhmm,hhmm\nxxx,Wednesday,Open,hhmm,hhmm\nxxx,Thursday,Open,hhmm,hhmm\nxxx,Friday,Open,hhmm,hhmm\nxxx,Saturday,Open,hhmm,hhmm\nxxx,Sunday,Open,hhmm,hhmm";
      const specialHoursResponse = "Store Code,Date,Open or Closed,Open at,Closes at,Short Description (optional)\nxxx,YYYYMMDD,Open,hhmm,hhmm";
      const findName = this.options.find(v => v.value == this.selectedKPI)?.fileName
      const selectedKPI = findName + '-template.csv'
      // const selectedKPI = this.selectedKPI + '-template.csv';
      const fileType = 'text/csv';
      if(this.selectedKPI == 'sls')
        downloadAsBlob(salesResponse, fileType, selectedKPI);

      else if(this.selectedKPI == 'st')
        downloadAsBlob(salesTargetResponse, fileType, selectedKPI);

      else if(this.selectedKPI == 'trf')
        downloadAsBlob(trafficResponse, fileType, selectedKPI);

      else if(this.selectedKPI == 'sh')
        downloadAsBlob(staffHoursResponse, fileType, selectedKPI);
      else if(this.selectedKPI == 'roh')
        downloadAsBlob(regularHoursResponse, fileType, selectedKPI);
      else if(this.selectedKPI == 'soh')
        downloadAsBlob(specialHoursResponse, fileType, selectedKPI);
    },

    downloadAsBlob(raw, type, filename) {
      const blob = new Blob([raw], { type });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    isValidTime(time) {
      return /^(?:([01]\d|2[0-3]):?([0-5]\d))$/.test(time);
    //  return /^([01]\d|2[0-3])[0-5]\d$/.test(time);
    },
    validateCSVData(data, stores) {
      let successCount = 0;
      let errorCount = 0;
      let validRows = [];
      let errors = [];
      
      let headers = `Store Code,${this.selectedKPI === 'roh' ? 'Day' : 'Date'}, Open or Closed, Open at, Closes at ${this.selectedKPI === 'soh' ? ',Short Description (optional)' : ''}`;
      let expectedHeaders = headers.split(',');
      // Validate headers
      if (data.length === 0 || !this.arraysMatch(data[0], expectedHeaders)) {
        return { successCount, errorCount, validRows, headersError: true };
      }
      const {error_import_invalid_storecode, error_import_invalid_day, error_import_invalid_open_at_close_at, error_import_invalid_open_closed, error_import_invalid_date} = this.$store.state.user.translate;
      // Validate data rows
      for (let i = 1; i < data.length; i++) {
        const row = data[i].map(cell => cell.trim());
        if (row.length !== expectedHeaders.length) {
          errorCount++;
          continue;
        }
        const [storeCode, dayOrDate, openOrClosed, openAt, closesAt] = row;
        if (!storeCode || !dayOrDate || !openOrClosed || !openAt || !closesAt) {
          errorCount++;
          continue;
        }
        
        if (!stores.includes(storeCode)) {
          errorCount++;
          errors.push({ row: i, error: `${error_import_invalid_storecode}`?.replace("%@", i).replace("%@", storeCode) });
          continue;
        }

        if (this.selectedKPI === 'roh') {
          const validDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
          if (!validDays.includes(dayOrDate.toLowerCase())) {
            errorCount++;
            errors.push({ row: i, error: `${error_import_invalid_day}`?.replace("%@", i).replace("%@", dayOrDate) });
            continue;
          }
        }

        if (this.selectedKPI === 'soh' && !moment(dayOrDate, "YYYYMMDD", true).isValid()) {
          errorCount++;
          errors.push({ row: i, error:`${error_import_invalid_date}`?.replace("%@", i).replace("%@", dayOrDate) });
          continue;
        }

        if (!["open", "closed"].includes(openOrClosed.toLowerCase())) {
          errorCount++;
          errors.push({ row: i, error: `${error_import_invalid_open_closed?.replace("%@", i)}`});
          continue;
        }

        if (!this.isValidTime(openAt) || !this.isValidTime(closesAt)) {
          errorCount++;
          errors.push({ row: i, error: `${error_import_invalid_open_at_close_at?.replace("%@", i)} ${!this.isValidTime(openAt)? 'close At':'open At'}` });
          continue;
        }
        successCount++;
        validRows.push(row);
      }
      return { successCount, errorCount, validRows, errors };
    },

    arraysMatch(arr1, arr2) {
      return arr1.length === arr2.length && arr1.every((value, index) => value.trim() === arr2[index].trim());
    },
    makePayload(data) {
        return data?.map(([_, day, status, openingTime, closingTime, description]) => {
          const isROH = this.selectedKPI === 'roh';
          const isSOH = this.selectedKPI === 'soh';      
          return {
            [isROH ? 'day' : 'dates']: isROH ? day : [moment(day, "YYYYMMDD").format("YYYY-MM-DD")],
            openingTime,
            status,
            closingTime: moment(closingTime, "HH:mm")?.subtract(1, "hour").format("HH:mm"),
            ...(isSOH && { day: description }),
            storeNumber: _,
          };
        });
    },
    async callUpdateApi(res, storeCodes){
      const err = res?.errors?.map(item => item.error);
      if(res?.validRows?.length){
        const payload = this.makePayload(res?.validRows);
        const sheetStores = res?.validRows.map(item => item[0]);
        const oStores = storeCodes.filter(item => sheetStores.includes(item.storeNumber))
        ///
        const req = oStores?.map(store => {
          const updatedTimings = store.storeTimings.map(item => {
              const newTime = this.selectedKPI == 'roh'? payload.find(newItem => newItem.day?.toLowerCase() === item.day?.toLowerCase() && newItem.storeNumber == store.storeNumber): payload.find(newItem => 
                newItem.dates && item.dates && newItem.storeNumber == store.storeNumber && 
                newItem.dates.every(date => item.dates.includes(date)));
              return newTime ? newTime : item;
          });
          // Add new items from payload that do not exist in storeTimings
          const newEntries = payload.filter(newItem => 
              newItem.storeNumber === store.storeNumber && (  // Ensure only adding for the correct store
                  this.selectedKPI === 'roh'
                      ? !store.storeTimings.some(item => item.day?.toLowerCase() === newItem.day?.toLowerCase())
                      : !store.storeTimings.some(item => (newItem.dates.every(date => item.dates?.includes(date)))
                      )
              )
          );
          return {
            storeId:store._id,
            storeTimings:[...updatedTimings, ...newEntries],
          }
        })
        // rem timing storeNumber
        const updatedStores = req?.map(store => ({
            ...store,
            storeTimings: store.storeTimings.map(timing => {
                const { storeNumber, openingTime,closingTime,day, ...rest } = timing; // Remove storeNumber if it exists
                return {...rest,day:this.selectedKPI=="roh" ? day?.toLowerCase(): day, ...(openingTime && {openingTime: moment(openingTime, "HH:mm")?.format("HH:mm")}), ...(closingTime && {closingTime: moment(closingTime, "HH:mm")?.format("HH:mm")}),};
            })
        }));
        //
        const  cleanStoreTimings = (data) => {
            return data.map(store => ({
              ...store,
              storeTimings: store.storeTimings
                .filter(timing => this.selectedKPI === 'roh' ? timing.status?.toLowerCase() !== "closed": timing  ) 
                .map(timing => {
                  if (timing.status?.toLowerCase() === 'closed') {
                    const { status, openingTime, closingTime,...rest } = timing;
                    return rest; 
                  }else {
                    const { status, ...rest } = timing;
                    return rest; 
                  }
                  // return timing; 
                })
            }));
          }
        // console.log("res:",res, 'payload:', cleanStoreTimings(updatedStores), 'storeCodes:', storeCodes)
        //
        try {
            const responses = await Promise.all(
              cleanStoreTimings(updatedStores)?.map(details => stores.updateStore(details, true))
            );
            if(responses){
              if(!res.errorCount){
                this.showUploadResult({
                  "failedCount": 0,
                  "failedList": "success",
                  "message": "success",
                  "successCount": res.successCount
                 })
              }else{
                this.showUploadResult({
                 "failedCount": res.errorCount,
                 "failedList": err?.toString(),
                 "message": err?.toString(),
                 "successCount": res.successCount
               })
              }
            }
            return responses;
          } catch (error) {
            this.showUploadResult({
             "message": "error",
             "successCount": 0,
             "failedCount":  1
            })
            console.error("Error updating stores:", error);
          }
      }else{
        this.showUploadResult({
                "failedCount": res.errorCount,
                "failedList": err?.toString(),
                "message": err?.toString(),
                "successCount": res.successCount
            })
      }
    }, 
    hoursUpdate(file, stores){
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        // Parse CSV manually
        const rows = text
          .trim()
          .split("\n")
          .map(row => row.split(",")); // Split each row by comma
        const arrayS = stores.map(item => item.storeNumber)
        const res =  this.validateCSVData(rows, arrayS);
        if(res.headersError){
          this.showUploadResult({
             "message": "columnMismatch",
             "successCount": 0,
             "failedCount": 1
         })
        }else{
          this.callUpdateApi(res, stores);
        }
      };
      reader.readAsText(file);
    },
    async handleUpload(file) {
      if (!file) return;
      try {
        var storeCodes;
        var finaltoresList;
        this.loading = true;
        if(!this.getStoreCodeList)
        {
          storeCodes = await this.fetchStores();
          finaltoresList = storeCodes.data.stores.map(x => x.storeCode);
        }
        else
        {
          finaltoresList = this.getStoreCodeList;
        }
        
        if(this.selectedKPI === 'soh' || this.selectedKPI === "roh"){
          const allStores = await stores.getStores();
          const sData = allStores.data.response?.stores;
          this.hoursUpdate(file, sData);
          return;
        }
      
        const response = await imports.importReportData(
          file,
        //  this.selectedSalesMappingTemplate.id,
          this.selectedKPI,
          finaltoresList,
          this.getLocale
        );
        this.loading = false;
        this.importData = response;
      
        if (response == "" || ((response.successCount?response.successCount:0) >0) || response?.status==200) {
          
          await this.showUploadResult(response);

          // this.$q.notify({
          //   message: $store.state.user.translate.importedSuccessfully,
          //   color: 'green'
          // });
        } else {
          await this.showUploadResult(response);
          // this.$q.notify({
          //   message: response.message,
          //   color: 'red'
          // });
        }
      } catch (error) {
        this.loading = false;
        await this.showUploadResult({
            message: this.$t(`Something went wrong`)
          })
         
        this.importData = error?.response?.data || error;
        const errorMessage = this.$t(`Something went wrong`);//this.$store.state.user.translate.something_went_wrong;
        this.$q.notify({
          message: errorMessage,
          color: 'red'
        });
      }
    },
    async showUploadResult(err) {
  
      if(err.message && err.message?.error && err.message?.error?.key)
      
      {
        var failCount = err.message?.error?.failed;
        err.message = err.message?.error?.key;
        err.failedCount = failCount?failCount:err.failedCount;
      }
      const randomFactor = Math.random()
        .toString(36)
        .substr(2);
      const idSrc = `000000${randomFactor}`;
      const id = idSrc.substr(idSrc.length - 6);
      const res = await this.$repo.extension.saveUploadResult(
        id,
        err,
        this.selectedKPI
      );




      // if( message == '{{successCount}} data(s) has been successfully imported, but there are still {{failedCount}} error(s)')
      // {
      //   message = this.$t('Extension.SuccessFailCount')
      // }

      // if( message == 'There are {{failedCount}} error(s)')
      // {
      //   message = this.$t('Extension.ThereError')
      // }
      //  message = message.replace(`{{failedCount}}`, res.failedCount);


      // if( message == '{{successCount}} data(s) has been successfully imported')
      // {
      //   message = this.$t('Extension.SuccessMsg')
      // }
      // message = message.replace(`{{failedCount}}`, 0);

      // message = message.replace(`{{successCount}}`, res.successCount);

      // if ([400, 401, 403, 402].includes(res?.status)) {
      //   if(message=='No message available') {
      //     this.$q.notify({
      //       message: this.$t('Extension.Message'),
      //       position: 'top',
      //       type: 'negative'
      //       });
      //     }
      //   else if(message=='Unexpected error has occurred') {
      //      this.$q.notify({
      //       message: this.$t('Extension.UnexpectedError'),
      //       position: 'top',
      //       type: 'negative'
      //       });
      //   }
      //   else if(message=='Failed to upload because the file format is not valid') {
      //      this.$q.notify({
      //       message: this.$t('Extension.FailedToUpload'),
      //       position: 'top',
      //       type: 'negative'
      //       });
      //   }
      //   else {
      //     this.$q.notify({
      //     message: message,
      //     position: 'top',
      //     type: 'negative'
      //     });
      //   }
      // } else {
      //   if(message=='No message available') {
      //     this.$q.notify({
      //       message: this.$t('Extension.Message'),
      //       position: 'top',
      //       type: 'positive'
      //     });
      //   }
      //   else
      //   if(message=='Invalid header file'){
      //     this.$q.notify({
      //       message:  this.$t('Import.invalid_header'),
      //       position: 'top',
      //       type: 'negative'
      //     })
      //   }
      //   else
      //   if(message=='Failed to upload because the file format is not valid') {
      //      this.$q.notify({
      //       message: this.$t('Extension.FailedToUpload'),
      //       position: 'top',
      //       type: 'negative'
      //       });
      //   }
      //   else{
      //     this.$q.notify({
      //       message: message,
      //       position: 'top',
      //       type: 'positive'
      //     });
      //   }
      // }
      await this.$router.push({
        name: 'tools:import:upload-result',
        params: {
          id
        }
      });
    },
  }
};
